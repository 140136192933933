
import { defineComponent } from "vue";
import { base64EncryptPostId } from "@/config/blog";

export default defineComponent({
  props: {
    post: {
      type: Object,
      required: true,
    },
    showLink: {
      // show link tag
      type: Boolean,
      default: true,
    },
  },

  methods: {
    getPostId(str: string | number) {
      if (base64EncryptPostId) return window.btoa(`${str}`);
      return str;
    },
  },

  computed: {
    computedContainer() {
      if (this.showLink) {
        return `router-link`;
      }
      return `div`;
    },
  },
});
