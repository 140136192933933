import axios from "@/plugins/axios";

const baseUrl = "https://sachdeva-sahab.tumblr.com"
const proxy = `https://course-search-proxy.herokuapp.com/`

export function getBlogPosts(): Promise<any> {
    const url = baseUrl + "/api/read/json?callback=xyz"

    return axios.post(proxy, {
        urlToGet: url
    })
        .then(parseResponseJSON)
}


export function getBlogPostsByTag(tag: string): Promise<any> {
    const url = baseUrl + "/api/read/json?callback=xyz&tagged=" + tag

    return axios.post(proxy, {
        urlToGet: url
    })
        .then(parseResponseJSON)
}

export function getBlogPost(id: string | number): Promise<any> {
    const url = baseUrl + "/api/read/json?callback=xyz&id=" + id

    return axios.post(proxy, {
        urlToGet: url
    })
        .then(parseResponseJSON)
}

export function parseResponseJSON(res: any): Array<any> {
    const data = res.data || res

    const xyz = function (data: any) {
        return data
    }

    const $data: any = eval(data)
    return $data.posts
}

export function checkAndAddAltTags(text = "Blog Post") {
    [...document.querySelectorAll("img")].forEach(function (img) {
        if (!img.alt) img.alt = text;
    });
}